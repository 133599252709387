@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');

body {
  background-color: #fce758;
  color: black;
  font-family: 'Archivo', sans-serif;
  display: grid;
  /* place-content: center; */
  align-content: center;
  justify-content: center;
  overflow: hidden; /* Hide scrollbars */
}

#centerSection {
  align-items: center;
  align-self: center;
  display: block;
  margin: auto;
  place-content: center;

  /* display: flex; */
  justify-content: center;
}
html,
body,
#app,
#app > div {
  height: 100%;
  width: 100%;
}

@media (min-aspect-ratio: 584/868) {
  h1 {
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: Archivo;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  #mainText {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  span {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  #cornerButton {
    width: 11.15588rem;
    height: 7.375rem;
    flex-shrink: 0;
    border: 1px solid #000;
    background-color: #fce758;
    transition-duration: 0.4s;
    position: absolute;
    top: -1rem;
    font-size: 2.2rem;
    justify-content: right;
    align-content: right;
    right: -1rem;
    color: #000;
  }

  #cornerButton:hover {
    cursor: pointer;
    font-size: 2.5rem;
  }
  #buttonText,
  #buttonText:visited {
    display: flex;
    width: 11.15588rem;
    height: 7.375rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  a {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    text-decoration: none;
  }
}

@media (max-aspect-ratio: 585/868) {
  h1 {
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: Archivo;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  h2 {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  #mainText {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
    margin: 0;
  }
  span {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  #cornerButton {
    width: 5.57794rem;
    height: 3.6875rem;
    flex-shrink: 0;
    border: 1px solid #000;
    background-color: #fce758;
    transition-duration: 0.4s;
    position: absolute;
    top: -1rem;
    right: -1rem;
    font-size: 1.2rem;
    justify-content: right;
    align-content: right;
    color: #000;
  }

  #cornerButton:hover {
    cursor: pointer;
    font-size: 1.5rem;
  }
  #buttonText,
  #buttonText:visited {
    display: flex;
    width: 5.57794rem;
    font-size: 0.9rem;
    height: 3.8875rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  a {
    color: #000;
    text-align: center;
    font-family: Archivo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    text-decoration: none;
  }
}

#topText {
  margin: 0;
  margin-bottom: 3.31rem;
  padding-bottom: 1.5rem;
  border-bottom-style: solid;
}

#topText {
  margin: 0;
  margin-bottom: 3.31rem;
}

.Link,
.Link:visited {
  display: flex;
  width: 11.15588rem;
  height: 7.375rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

a:hover {
  color: black;
  text-decoration: none;
}
